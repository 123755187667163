import React from "react";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import AdminLogin from "./AdminLogin";

export default function AuthPage() {
  console.log("authPage");
  return (
      <>
        <div className="kt-grid kt-grid--ver kt-grid--root">
          <div
              id="kt_login"
              className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
          >
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
              <div
                  className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
                  style={{
                    backgroundImage: `url(${toAbsoluteUrl("/media/exam/home.png")})`
                  }}
              >
                <div className="kt-grid__item">
                  <Link to="/" className="kt-login__logo">
                    <img
                        alt="Logo"
                        src={toAbsoluteUrl("/media/logos/real.png")}
                        style={{width:70, height:62}}
                    />
                  </Link>
                </div>
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                  <div className="kt-grid__item kt-grid__item--middle">
                    {/*<h3 className="kt-login__title">ESPAM FORMATION UNIVERSITY</h3>*/}
                    {/*<h4 className="kt-login__subtitle">*/}
                    {/*  ONLINE APPLICATION EXAM PORTAL*/}
                    {/*</h4>*/}
                  </div>
                </div>
                <div className="kt-grid__item">
                  <div className="kt-login__info">
                    <div className="kt-login__copyright">
                      &copy; 2020 Easy Education
                    </div>
                    <div className="kt-login__menu">
                      {/*<a href="https://espamformationuniversity.com" className="kt-link">*/}
                      {/*  Home*/}
                      {/*</a>*/}
                      {/*<a href="https://espamformationuniversity.com/about-us" className="kt-link">*/}
                      {/*  About*/}
                      {/*</a>*/}
                      {/*<a href="https://espamformationuniversity.com/blog" className="kt-link">*/}
                      {/*  blog*/}
                      {/*</a>*/}
                    </div>
                  </div>
                </div>
              </div>

              <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
                <Switch>
                  <Route path="/auth/login" component={Login} />
                  <Route path="/auth/adminlogin" component={AdminLogin} />
                  <Route path="/auth/registration" component={Registration} />

                  <Redirect from="/auth" exact={true} to="/auth/login" />
                  <Redirect to="/auth/login" />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </>
  );
}
