import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import  Exam from "./Exam";
import  SectionB from "./SectionB";
import  SectionC from "./SectionC";


export default function ExamHome() {
    return (
        <Switch>
            <Redirect from="/exam" exact={true} to="/exam/first-section" />
            <Route path="/exam/first-section" component={Exam} />
            <Route path="/exam/second-section" component={SectionB} />
            <Route path="/exam/third-section" component={SectionC} />
            <Redirect to="/error/error-v2" />
        </Switch>
    );
}
