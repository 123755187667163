import React, {useMemo, useState, useEffect, Component} from "react";
import {useSelector} from "react-redux";
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../partials/content/Portlet";
import {metronic} from "../../../_metronic";
import QuickStatsChart from "../../widgets/QuickStatsChart";
import OrderStatisticsChart from "../../widgets/OrderStatisticsChart";
import OrdersWidget from "../../widgets/OrdersWidget";
import SalesBarChart from "../../widgets/SalesBarChart";
import DownloadFiles from "../../widgets/DownloadFiles";
import NewUsers from "../../widgets/NewUsers";
import LatestUpdates from "../../widgets/LatestUpdates";
import BestSellers from "../../widgets/BestSellers";
import RecentActivities from "../../widgets/RecentActivities";
import PortletHeaderDropdown from "../../partials/content/CustomDropdowns/PortletHeaderDropdown";
import {http, endpoints} from "../../config/http";
import {Button, Spinner} from "react-bootstrap";

export default class Dashboard extends Component {

    state = {
        loading: false,
        a: "",
        b: "",
        c: "",
        type: "",
        students: "",
        questions: "",
    }

    componentDidMount() {
        this.loadStat();
    }

    // useEffect(() => {
    //     // Update the document title using the browser API
    //     loadStat();
    // });


    //
    // const [loading, setLoading] = useState(false);
    // const [type, setType] = useState("");
    // const [a, setA] = useState("");
    // const [b, setB] = useState("");
    // const [c, setC] = useState("");
    // const [students, setStudents] = useState("");
    // const [questions, setQuestions] = useState("");

    loadStat() {
        this.setState({
            loading: true
        });

        http.get(endpoints.STATS)
            .then(response => {
                // setType(response.data.type);
                // setLoading(false);
                this.setState({
                    loading: false,
                    type: response.data.type
                })

                if (response.data.type === "admin") {
                    this.setState({
                        students: response.data.users,
                        questions: response.data.questions
                    })

                } else {
                    this.setState({
                        a: response.data.a,
                        b: response.data.b,
                        c: response.data.c,
                    })
                }

            })
            .catch(e => {
                console.log(e);
                this.setState({
                    loading: false,
                })
            });

    }

    render() {
        const {loading, type, a, b, c, students, questions} = this.state;
        return (
            <div className="row">
                <div className="text-center">

                    {loading &&
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                    }
                </div>
                {type === "admin" &&
                <div className="col-xl-6">
                    <div className="row row-full-height">
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <Portlet className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand">
                                <PortletBody fluid={true}>
                                    <QuickStatsChart
                                        value={students}
                                        desc="Total Students"

                                    />
                                </PortletBody>
                            </Portlet>

                            <div className="kt-space-20"/>

                            <Portlet className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand">
                                <PortletBody fluid={true}>
                                    <QuickStatsChart
                                        value={questions}
                                        desc="Objective Questions"

                                    />
                                </PortletBody>
                            </Portlet>
                        </div>


                    </div>
                </div>

                }
                {type === "student" &&
                <div className="col-xl-6">
                    <div className="row row-full-height">
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <Portlet className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand">
                                <PortletBody fluid={true}>
                                    <QuickStatsChart
                                        value={a}
                                        desc="Section A"

                                    />
                                </PortletBody>
                            </Portlet>

                            <div className="kt-space-20"/>

                            <Portlet className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand">
                                <PortletBody fluid={true}>
                                    <QuickStatsChart
                                        value={c}
                                        desc="Section C"

                                    />
                                </PortletBody>
                            </Portlet>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <Portlet className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand">
                                <PortletBody fluid={true}>
                                    <QuickStatsChart
                                        value={b}
                                        desc="Section B"

                                    />
                                </PortletBody>
                            </Portlet>

                        </div>
                    </div>
                </div>

                }
            </div>


        )
            ;
    }
}
