import React, { Component } from 'react';
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../../App.css';
import Quiz from './Quiz';
import { connect } from 'react-redux';
import { ActionTypes } from '../../../../src/app/store/ducks/exams/constants/actionTypes';
import { ActionCreators } from  "../../../../src/app/store/ActionCreators";
import {bindActionCreators} from 'redux';
import Timer from "./Timer";
import {http, endpoints} from '../../config/http';


const mapStateToProps = (state) => ({
    quiz: state.quiz.quiz,
    mode: state.quiz.mode,
    auth: state.auth,
    AStartTime: state.quiz.AStartTime,
})

const mapDispatchToProps = dispatch => ({
    onQuizLoad: payload => dispatch({ type: ActionTypes.QuizLoad, payload }),
    onPagerUpdate: payload => dispatch({ type: ActionTypes.PagerUpdate, payload }),
    onSubmit: payload => dispatch({ type: ActionTypes.QuizSubmit, payload }),
    onUpdateTimer: payload => dispatch({ type: ActionTypes.AStartTimeUpdate, payload })
});
// const mapDispatchToProps = dispatch =>
//     bindActionCreators(
//         {
//             getExams: ActionCreators.actions.getExams,
//             getExamsList: ActionCreators.actions.getExamList,
//         },
//         dispatch,
//     );

class Exam extends Component {
    state = {
        quizes: [
            { id: 'data/javascript.json', name: 'Javascript' },
            { id: 'data/aspnet.json', name: 'Asp.Net' },
            { id: 'data/csharp.json', name: 'C Sharp' },
            { id: 'data/designPatterns.json', name: 'Design Patterns' }
        ],
        quizId: 'data/javascript.json',
        isLoading:false
    };

    pager = {
        index: 0,
        size: 1,
        count: 1
    }

    componentDidMount() {
        this.load(this.state.quizId);
        this.checkTaken();
    }

    setupDate(){
        var CurrentDate = new Date();
        console.log(CurrentDate);
        if (this.props.AStartTime === null){
            this.props.onUpdateTimer(CurrentDate);
            console.log("there" +this.props.AStartTime);
        }
        else {
            console.log("here" +this.props.AStartTime);
        }
        console.log(this.props.AStartTime);
        console.log(this.state.started_at);

    }

    load(quizId) {
        let url = quizId || this.props.quizId;
        // fetch(`../${url}`).then(res => res.json()).then(res => {
        //     let quiz = res;
        //     quiz.questions.forEach(q => {
        //         q.options.forEach(o => o.selected = false);
        //     });
        //     quiz.config = Object.assign(this.props.quiz.config || {}, quiz.config);
        //     this.pager.count = quiz.questions.length / this.pager.size;
        //     this.props.onQuizLoad(quiz);
        //     this.props.onPagerUpdate(this.pager);
        // });
            this.pager.count = this.props.quiz.questions.length / this.pager.size;
            this.props.onPagerUpdate(this.pager);
    }

    onChange = (e) => {
        this.setState({ quizId: e.target.value });
        this.load(e.target.value);
    }

    updateCbtScore(score){
        // console.log(this.props.quiz.score);
        http
            .put(endpoints.UPDATE_CBT_SCORE,{
                    score: score
                }
            )
            .then(res => {
                console.log(res);
                this.setState({
                    isLoading :false,
                })
                if (res.data.is_taken){
                    this.setState({
                        taken :true,
                    })
                    this.props.onUpdateTimer(null);
                    alert("Exam already taken");
                    window.location.replace("/take-exam");
                }else{
                    this.setState({
                        taken :false,
                    })


                }
                // this.checkTaken();
            })
            .catch(error => {

                this.setState({
                    isLoading: false
                });
                let errorMessage =
                    (typeof error === "string" && error) ||
                    "An error occured, please try again in a moment.";
                // alert(JSON.stringify(error));
                console.log(errorMessage);
            });
    }

    checkTaken(){
        console.log(this.props.auth);
        http
            .get(endpoints.ME
            )
            .then(res => {
                console.log(res);
                this.setState({
                    isLoading :false,
                })
                if (res.data.is_taken){
                    this.setState({
                        taken :true,
                    })
                    this.props.onUpdateTimer(null);
                    alert("Exam already taken.");
                    window.location.replace("/take-exam");
                }else{
                    this.setState({
                        taken :false,
                    })
                    this.setupDate();
                }
                // if (this.props.quiz.score !==  null && !res.data.is_taken && this.props.auth.user.id === this.props.quiz.score.userId){
                //     console.log("here");
                //     this.updateCbtScore(this.props.quiz.score.score);
                // }else {
                //     console.log("there");
                // }
            })
            .catch(error => {

                this.setState({
                    isLoading: false
                });
                let errorMessage =
                    (typeof error === "string" && error) ||
                    "An error occured, please try again in a moment.";
                // alert(JSON.stringify(error));
                console.log(errorMessage);
            });
    }

    render() {
        const timerdate = this.props.AStartTime === null? new Date() : this.props.AStartTime;
        return (
            <div className="container">
                <header className="p-2">
                    <div className="row">
                        <div className="col-6">
                            <img src="/media/logos/logo.jpg" className="logoImage" style={{width:70}}/>
                        </div>
                        {this.props.mode !== "submit" &&
                        <div className="col-6 text-right">
                            <label className="mr-1">Time:</label>
                            <Timer
                                style={[]}
                                startTime={timerdate}
                                duration={this.props.quiz.questions.length * 60}
                                onEnd={() => {
                                    this.setState({mode: 'submit'})
                                    this.props.onSubmit("submit");
                                }}
                                intervalText={(date, hour, min, sec) =>
                                    `${min}:${sec} left`
                                }
                            />
                            {/*<select onChange={this.onChange}>*/}
                            {/*    {this.state.quizes.map(q => <option key={q.id} value={q.id}>{q.name}</option>)}*/}
                            {/*</select>*/}
                        </div>
                        }
                    </div>
                </header>
                <Quiz quiz={this.state.quiz} quizId={this.state.quizId} mode={this.state.mode} />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Exam);
