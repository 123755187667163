import React, {Component} from 'react';
import {Table, Button, Spinner, Modal, Alert} from "react-bootstrap";
import {http, endpoints} from '../../../config/http';
import {Formik} from "formik";
import {TextField} from "@material-ui/core";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import clsx from "clsx";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Swal from "sweetalert2";
import DateTimePicker from 'react-datetime-picker';
import moment from "moment";
import store, {persistor} from "../../../store/store";

const useStyles = makeStyles(theme => ({
    button: {
        display: 'block',
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

class Students extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            students: [],
            show:false,
            isSubmitting: false,
            paddingRight: "2.5rem",
            open: false,
            cat: "",
            status: null,
            success:false,
            currentStudent: null,
            showSectionC:false,
            showSectionB:false,
            sectionCscore: null,
            sectionBscore: null,
            isSubmittingScore: false,
            isDeleting: false,
            exam_deadline: null,
            value: new Date(),
            showEditModal: false,
        };

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.enableLoadling = this.enableLoadling.bind(this);
        this.disableLoadling = this.disableLoadling.bind(this);
        this.handleOpenSelect = this.handleOpenSelect.bind(this);
        this.handleCloseSelect = this.handleCloseSelect.bind(this);
        this.handleChangeCat= this.handleChangeCat.bind(this);
        this.showSectionModal = this.showSectionModal.bind(this);
        this.closeSectionModal = this.closeSectionModal.bind(this);
        this.handleSubmitDeadline = this.handleSubmitDeadline.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    showSectionModal (student){
        this.setState({
            currentStudent: student,
            showEditModal: true,
            exam_deadline: student.exam_deadline,
            value: new Date(student.exam_deadline)
        })
    }
    handleDelete = (id) => {
        Swal.fire({
            title:
                "Are you sure you want to delete student "
            ,
            text:
                "You won't be able to revert this this",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Delete",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33"
        }).then(result => {
            if (result.value) {
                this.setState({
                    isDeleting: true
                });
                http
                    .delete(endpoints.DELETE_USER.replace(":id",id)
                    )
                    .then(res => {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000
                        });

                        Toast.fire({
                            icon: "success",
                            title: "User deleted successfully "
                        });
                        this.setState({
                            isDeleting: false
                        });
                        this.loadStudents();

                    })
                    .catch(error => {
                        this.setState({
                            isDeleting: false
                        });
                        let errorMessage =
                            (typeof error === "string" && error) ||
                            "An error occured, please try again in a moment.";

                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000
                        });

                        Toast.fire({
                            icon: "error",
                            title: errorMessage
                        });
                        // alert(JSON.stringify(error));
                        console.log(errorMessage);
                    });
            }
        });
    };


    handleReset = (id) => {
        Swal.fire({
            title:
                "Are you sure you want to reset exam "
            ,
            text:
                "Applicant will be able to retake exam",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Reset",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33"
        }).then(result => {
            if (result.value) {
                this.setState({
                    isDeleting: true
                });
                http
                    .put(endpoints.RESET.replace(":id",id)
                    )
                    .then(res => {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000
                        });

                        Toast.fire({
                            icon: "success",
                            title: "Exam reset successfully"
                        });
                        this.setState({
                            isDeleting: false
                        });
                        this.loadStudents();

                    })
                    .catch(error => {
                        this.setState({
                            isDeleting: false
                        });
                        let errorMessage =
                            (typeof error === "string" && error) ||
                            "An error occured, please try again in a moment.";

                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000
                        });

                        Toast.fire({
                            icon: "error",
                            title: errorMessage
                        });
                        // alert(JSON.stringify(error));
                        console.log(errorMessage);
                    });
            }
        });
    };
    closeSectionModal(){
        this.setState({
            currentStudent: null,
            showEditModal: false,
            exam_deadline: null,
            value: new Date(),
        })
    }
    onChange = value => {
        this.setState({
            value ,
            exam_deadline: moment(value).format('YYYY-MM-DDTHH:mm:ss.sssZ')
        });

    }
    handleSubmitDeadline(event){
        event.preventDefault();
        this.setState({
            isSubmittingScore :true,
        })
        http
            .put(endpoints.UPDATE_DEADLINE.replace(':id',this.state.currentStudent.id),
                {deadline: this.state.exam_deadline}
            )
            .then(res => {
                this.setState({
                    isSubmittingScore :false,
                })
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000
                });

                Toast.fire({
                    icon: "success",
                    title: "deadline updated"
                });
                this.loadStudents();
                this.closeSectionModal();
            })
            .catch(error => {

                this.setState({
                    isSubmittingScore :false,
                })
                let errorMessage =
                    (typeof error === "string" && error) ||
                    "An error occured, please try again in a moment.";
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000
                });

                Toast.fire({
                    icon: "error",
                    title: errorMessage
                });
                // alert(JSON.stringify(error));
                console.log(errorMessage);
            });
    }

    componentDidMount(){
        const {
            auth: { user }
        } = store.getState();
        if (user.role != "admin"){
            window.location.replace("/dashboard");
        }
        this.loadStudents();
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }
    handleChangeCat(event) {
        this.setState({ cat: event.target.value });
    }

    enableLoadling() {
        this.setState({ isSubmitting: true,paddingRight: "3.5rem",success: false });
    }
    disableLoadling() {
        this.setState({ isSubmitting: false,paddingRight: "2.5rem" });
    }
    addStudent(firstname, lastname,login_id,phone_number, email){
        return http.post(endpoints.ADD_STUDENT, {
            firstname,
            lastname,
            login_id,
            phone_number,
            email,
            deadline: this.state.exam_deadline
        });
    }

    handleCloseSelect() {
        this.setState({ open: false });

    }

    handleOpenSelect() {
        this.setState({ open: true });
    }




    loadStudents(){
        this.setState({
            isLoading: true
        });
        http
            .get(endpoints.GET_STUDENTS
            )
            .then(res => {
                console.log(res);
                this.setState({
                    isLoading :false,
                    students: res.data
                })
            })
            .catch(error => {

                this.setState({
                    isLoading: false
                });
                let errorMessage =
                    (typeof error === "string" && error) ||
                    "An error occured, please try again in a moment.";
                // alert(JSON.stringify(error));
                console.log(errorMessage);
            });
    }
    handleChange(event) {
        const {name, value} = event.target;
        this.setState({
            [name]: value
        });
    }

    getRowsData = function() {
        const {students} = this.state;
        return students.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.lastname} {item.firstname}</td>
                    <td>{item.login_id} </td>
                    <td>{item.exam_score != null? <a href="#" onClick={() => this.handleReset(item.id)}>{item.exam_score}</a> :"Not taken"} </td>
                    <td> {moment(item.exam_deadline).format('MMMM Do hh:mm:ss a')} </td>

                    {/*<td>{item.is_taken_third ? <a href="#" onClick={()=> this.showSectionModal(item, "C")}> Taken {item.section_three_score? " - "+item.section_three_score:""}</a> :"Not taken"} </td>*/}
                    <td><a href="#" className='btn btn-primary' onClick={()=> this.showSectionModal(item)}>Edit</a>  <Button variant="danger" onClick={() => this.handleDelete(item.id)} disabled={this.state.isDeleting}>Delete</Button></td>
                </tr>
            );
        });
    };

    render() {
        const {isLoading, isSubmitting, success, currentStudent,isSubmittingScore} = this.state;
        return (
            <div className="container">
                <div>
                        <Button variant="primary" size="lg" active  style={{float:'right'}} onClick={this.handleShow}>
                            Add New Student
                        </Button>
                </div>
                {isLoading &&
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
                }

                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Login Id</th>
                        <th>Exam Score</th>
                        <th>Deadline</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.getRowsData()}
                    </tbody>
                </Table>

                <Modal show={this.state.show} onHide={this.handleClose} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Add New Student</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            initialValues={{
                                firstname: "",
                                lastname: "",
                                login_id: "",
                                phone_number: "",
                                email: "",
                                exam_deadline :moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.sssZ'),
                            }}
                            validate={values => {
                                const errors = {};

                                if (!values.firstname) {
                                    errors.firstname = "First Name is required"
                                }

                                if (!values.lastname) {
                                    errors.lastname = "Last Name is required"
                                }
                                if (!values.login_id) {
                                    // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                                    errors.login_id = "Field is required"
                                }
                                return errors;
                            }}
                            onSubmit={(values, { setStatus, setSubmitting }) => {
                                this.enableLoadling();
                                setSubmitting(true);
                                setStatus(null);
                                setTimeout(() => {
                                    this.addStudent(values.firstname,values.lastname,values.login_id,values.phone_number, values.email)
                                        .then(response => {
                                            // alert(accessToken);
                                            this.setState({ paddingRight: "2.5rem", success : true });
                                            this.loadStudents();
                                            setSubmitting(false);
                                            values.login_id = "";
                                            values.firstname = "";
                                            values.lastname ="";
                                            values.email = "";
                                                values.phone_number = "";

                                        })
                                        .catch(e => {
                                            console.log(e);
                                            setSubmitting(false);
                                            this.setState({ paddingRight: "2.5rem", success : false });
                                            setStatus(e);
                                        });
                                }, 1000);
                            }}
                        >
                            {({
                                  values,
                                  status,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting
                              }) => (
                                <form
                                    noValidate={true}
                                    autoComplete="off"
                                    className="kt-form"
                                    onSubmit={handleSubmit}
                                >
                                    {status &&
                                        <div role="alert" className="alert alert-danger">
                                            <div className="alert-text">{status}</div>
                                        </div>
                                  }
                                    {success &&
                                    <Alert variant='success'>
                                        Student has been created
                                    </Alert>
                                    }


                                    <div className="form-group">
                                        <TextField
                                            type="text"
                                            label="Surname"
                                            margin="normal"
                                            className="kt-width-full"
                                            name="lastname"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.lastname}
                                            helperText={touched.lastname && errors.lastname}
                                            error={Boolean(touched.lastname && errors.lastname)}
                                        />
                                    </div>

                                    <div className="form-group">
                                    <TextField
                                        type="text"
                                        label="First Name"
                                        margin="normal"
                                        className="kt-width-full"
                                        name="firstname"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.firstname}
                                        helperText={touched.firstname && errors.firstname}
                                        error={Boolean(touched.firstname && errors.firstname)}
                                    />
                                </div>
                                    <div className="form-group">
                                        <TextField
                                            type="text"
                                            label="Login Id"
                                            margin="normal"
                                            className="kt-width-full"
                                            name="login_id"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.login_id}
                                            helperText={touched.login_id && errors.login_id}
                                            error={Boolean(touched.login_id && errors.login_id)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            type="text"
                                            label="Phone number"
                                            margin="normal"
                                            className="kt-width-full"
                                            name="phone_number"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.phone_number}
                                            helperText={touched.phone_number && errors.phone_number}
                                            error={Boolean(touched.phone_number && errors.phone_number)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            type="text"
                                            label="Email"
                                            margin="normal"
                                            className="kt-width-full"
                                            name="email"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.email}
                                            helperText={touched.email && errors.email}
                                            error={Boolean(touched.email && errors.email)}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <InputLabel htmlFor="demo-controlled-open-select">Exam Deadline</InputLabel>
                                        <FormControl>


                                            <div className="Sample__container">
                                                <main className="Sample__container__content">
                                                    <DateTimePicker
                                                        amPmAriaLabel="Select AM/PM"
                                                        calendarAriaLabel="Toggle calendar"
                                                        clearAriaLabel="Clear value"
                                                        dayAriaLabel="Day"
                                                        hourAriaLabel="Hour"
                                                        maxDetail="second"
                                                        closeWidgets={true}
                                                        minuteAriaLabel="Minute"
                                                        monthAriaLabel="Month"
                                                        nativeInputAriaLabel="Date and time"
                                                        onChange={this.onChange}
                                                        secondAriaLabel="Second"
                                                        value={this.state.value}
                                                        minDate={new Date()}
                                                        yearAriaLabel="Year"
                                                    />
                                                </main>
                                            </div>

                                        </FormControl>
                                    </div>

                                    <div className="kt-login__actions">
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={this.handleClose}>
                                                Close
                                            </Button>
                                            <Button variant="primary" type="submit" disabled={isSubmitting}>
                                                Save Changes        {isSubmitting &&
                                            <Spinner animation="border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                            }
                                            </Button>
                                        </Modal.Footer>

                                    </div>
                                </form>
                            )}
                        </Formik>

                    </Modal.Body>

                </Modal>

                {currentStudent != null &&
                <Modal show={this.state.showEditModal} onHide={this.closeSectionModal} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Change deadline for {this.state.currentStudent.login_id}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <form onSubmit={this.handleSubmitDeadline}>
                            <div className="form-group">

                                <div className="Sample__container">
                                    <main className="Sample__container__content">
                                        <DateTimePicker
                                            amPmAriaLabel="Select AM/PM"
                                            calendarAriaLabel="Toggle calendar"
                                            clearAriaLabel="Clear value"
                                            dayAriaLabel="Day"
                                            hourAriaLabel="Hour"
                                            maxDetail="second"
                                            closeWidgets={true}
                                            minuteAriaLabel="Minute"
                                            monthAriaLabel="Month"
                                            nativeInputAriaLabel="Date and time"
                                            onChange={this.onChange}
                                            secondAriaLabel="Second"
                                            value={new Date(currentStudent.exam_deadline)}
                                            minDate={new Date()}
                                            yearAriaLabel="Year"
                                        />
                                    </main>
                                </div>
                                {/*<label className="form-label" htmlFor="q12">Update section B score</label>*/}
                                {/*<input*/}
                                {/*    placeholder="" id="sectionBscore" className="form-control"*/}
                                {/*    value={this.state.sectionBscore} name="sectionBscore" onChange={this.handleChange}/>*/}
                            </div>
                            <div className="kt-login__actions">
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.closeSectionModal}>
                                        Close
                                    </Button>
                                    <Button variant="primary" type="submit" disabled={isSubmittingScore}>
                                        Save Changes {isSubmittingScore &&
                                    <Spinner animation="border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>
                                    }
                                    </Button>
                                </Modal.Footer>

                            </div>
                        </form>

                    </Modal.Body>

                </Modal>


                }
                {currentStudent !== null &&
                <Modal show={this.state.showSectionC && currentStudent.section_c != null} onHide={this.closeSectionModal} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Section C result for {this.state.currentStudent.matric_no}</Modal.Title>
                    </Modal.Header>
                    {currentStudent.section_c &&
                    <Modal.Body>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Answer</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>{currentStudent.section_c.q1}</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>{currentStudent.section_c.q2}</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>{currentStudent.section_c.q3}</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>{currentStudent.section_c.q4}</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>{currentStudent.section_c.q5}</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>{currentStudent.section_c.q6}</td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>{currentStudent.section_c.q7}</td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>{currentStudent.section_c.q8}</td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>{currentStudent.section_c.q9}</td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>{currentStudent.section_c.q10}</td>
                            </tr>
                            <tr>
                                <td>11</td>
                                <td>{currentStudent.section_c.q11}</td>
                            </tr>
                            <tr>
                                <td>12</td>
                                <td>{currentStudent.section_c.q12}</td>
                            </tr>
                            <tr>
                                <td>13</td>
                                <td>{currentStudent.section_c.q13}</td>
                            </tr>
                            <tr>
                                <td>14</td>
                                <td>{currentStudent.section_c.q14}</td>
                            </tr>
                            <tr>
                                <td>15</td>
                                <td>{currentStudent.section_c.q15}</td>
                            </tr>
                            </tbody>
                        </Table>

                        <form onSubmit={this.handleSubmitThirdScore}>
                            <div className="form-group">
                                <label className="form-label" htmlFor="q12">Update section C score</label>
                                <input
                                    placeholder="" id="sectionCscore" className="form-control"
                                    value={this.state.sectionCscore} name="sectionCscore" onChange={this.handleChange}/>
                            </div>
                            <div className="kt-login__actions">
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.closeSectionModal}>
                                        Close
                                    </Button>
                                    <Button variant="primary" type="submit" disabled={isSubmittingScore}>
                                        Save Changes {isSubmittingScore &&
                                    <Spinner animation="border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>
                                    }
                                    </Button>
                                </Modal.Footer>

                            </div>
                        </form>

                    </Modal.Body>
                    }
                </Modal>
                }

            </div>
        );
    }
}

export default Students;
