import React, {Component} from 'react';
import store, {persistor} from "../../../store/store";
import { connect } from 'react-redux';
import { ActionCreators } from  "../../../../app/store/ActionCreators";
import {bindActionCreators} from 'redux';
import { ActionTypes } from '../../../../../src/app/store/ducks/exams/constants/actionTypes';
import {http, endpoints} from '../../../config/http';
import {Spinner} from "react-bootstrap";
import {isLoading} from "sweetalert2";
import {Link} from "react-router-dom";

class ExamHome extends Component {
    constructor() {
        super();
        this.state = {
            taken: false,
            isLoading:false,
            takenB: false,
            takenC:false
        };
        this.loadExam = this.loadExam.bind(this);


    }
    componentDidMount(){
        const {
            auth: { user }
        } = store.getState();
        if (user.role != "student"){
            window.location.replace("/dashboard");
        }
        this.checkTaken();
    }
    loadExam(){
        const config = {
            'allowBack': true,
            'allowReview': true,
            'autoMove': false,  // if true, it will move to next question automatically when answered.
            'duration': 5,  // indicates the time in which quiz needs to be completed. 0 means unlimited.
            'pageSize': 1,
            'requiredAll': false,  // indicates if you must answer all the questions before submitting.
            'richText': false,
            'shuffleQuestions': false,
            'shuffleOptions': false,
            'showClock': true,
            'showPager': true,
            'theme': 'none'
        };

            this.setState({
                isLoading: true
            });
            http
                .get(endpoints.GET_QUESTIONS
                )
                .then(res => {
                    console.log(res);
                    this.setState({
                        isLoading :false,
                    })
                    console.log(res.data);
                    var result = res.data;
                    result.config = config;
                    this.props.onQuizLoad(result);
                    window.location.replace('/exam');
                })
                .catch(error => {

                    this.setState({
                        isLoading: false
                    });
                    let errorMessage =
                        (typeof error === "string" && error) ||
                        "An error occured, please try again in a moment.";
                    // alert(JSON.stringify(error));
                    console.log(errorMessage);
                });



    }

    updateCbtScore(score){
        // console.log(this.props.quiz.score);
        http
            .put(endpoints.UPDATE_CBT_SCORE,{
                score: score
                }
            )
            .then(res => {
                console.log(res);
                this.setState({
                    isLoading :false,
                })
                if (res.data.is_taken){
                    this.setState({
                        taken :true,
                    })
                }else{
                    this.setState({
                        taken :false,
                    })
                }
              // this.checkTaken();
            })
            .catch(error => {

                this.setState({
                    isLoading: false
                });
                let errorMessage =
                    (typeof error === "string" && error) ||
                    "An error occured, please try again in a moment.";
                // alert(JSON.stringify(error));
                console.log(errorMessage);
            });
    }

    checkTaken(){
        console.log(this.props.auth);
        http
            .get(endpoints.ME
            )
            .then(res => {
                console.log(res);
                this.setState({
                    isLoading :false,
                })
                if (res.data.is_taken){
                    this.setState({
                        taken :true,
                    })
                }else{
                    this.setState({
                        taken :false,
                    })
                }

                // if (this.props.quiz.score !==  null && !res.data.is_taken && this.props.auth.user.id === this.props.quiz.userId){
                //     console.log("here");
                //     this.updateCbtScore(this.props.quiz.score);
                // }else {
                //     console.log("there");
                // }
            })
            .catch(error => {

                this.setState({
                    isLoading: false
                });
                let errorMessage =
                    (typeof error === "string" && error) ||
                    "An error occured, please try again in a moment.";
                // alert(JSON.stringify(error));
                console.log(errorMessage);
            });
    }

    render() {
        const {taken, isLoading, takenB, takenC} = this.state;
        return (
            <div>
                <h2><span
                    className="kt-section__sub">Be sure you have a good internet connection before starting your exam.</span></h2>
                {taken &&
                <div role="alert" className="alert alert-light alert-elevate">
                    <div className="alert-icon alert-icon-top"><i className="flaticon-warning kt-font-primary"></i>
                    </div>
                    <div className="alert-text">You already took your exam
                        <br/>
                            Contact admin if you have any issues with your application exam.
                            <br/>
                        Best of luck to you!!!
                    </div>
                </div>
                }
                {!taken &&
                <div className="kt-portlet">
                    <div className="kt-portlet__head">
                        <div className="kt-portlet__head-label"><h3 className="kt-portlet__head-title">Instruction</h3></div>
                        <div className="kt-portlet__head-toolbar">

                        </div>
                    </div>
                    <div className="kt-portlet__body">
                        <div className="kt-portlet__preview">
                            <div className="kt-section">
                                <div>
                                    Multiple choice questions (25)
                                </div>

                                <div className="kt-separator kt-separator--dashed"></div>
                                <button type="button" className="btn btn-primary" onClick={()=> this.loadExam()} disabled={isLoading}>Start Exam
                                    {isLoading &&
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                                }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                }

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    quiz: state.quiz,
    auth: state.auth,
})
const mapDispatchToProps = dispatch => ({
    onQuizLoad: payload => dispatch({ type: ActionTypes.QuizLoad, payload }),
});

ExamHome = connect(mapStateToProps,mapDispatchToProps)(ExamHome)
export default ExamHome;
